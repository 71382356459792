import { addMonths, format, parseISO } from 'date-fns';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { CompanyUsage, Plan } from '../../generated-api';

interface Props {
  billingCompany: string;
  plan: Plan;
  usage: CompanyUsage;
}

const Invoice = ({ billingCompany, plan, usage }: Props): ReactElement => {
  const totalUsage = usage.usage - usage.free_usage;
  const date = format(Date.now(), 'yyyy年 MM月 dd日');
  const usageMonth = format(parseISO(usage.month), 'yyyy年M月');
  const billMonth = format(addMonths(parseISO(usage.month), 1), 'yyyy年M月');
  const paymentDue = `${billMonth}末`;
  const fee = plan.fee;
  const rows = [
    {
      name: `Ez-AiOCR ${plan.plan_name} 基本料金(${usageMonth} ご利用分)`,
      quantity: 1,
      unitPrice: plan.fee[0].base,
    },
  ];

  if (fee.length === 1) {
    const minThisTier = fee[0].minimum_page;
    const usageThisTier = Math.max(totalUsage - minThisTier, 0);
    const rate = fee[0].rate;
    const name = `Ez-AiOCR ${plan.plan_name} 従量課金(${usageMonth} ご利用分)(${minThisTier + 1}枚 ~)`;
    rows.push({
      name: name,
      quantity: usageThisTier,
      unitPrice: rate,
    });
  } else {
    for (let i = 0; i < fee.length; ++i) {
      const minThisTier = fee[i].minimum_page;
      const maxThisTier = i === fee.length - 1 ? Number.MAX_SAFE_INTEGER : fee[i + 1].minimum_page;
      const usageThisTier = Math.max(Math.min(totalUsage, maxThisTier) - minThisTier, 0);
      const rate = fee[i].rate;
      const name =
        i === 0
          ? `Ez-AiOCR ${plan.plan_name} 従量課金(${usageMonth} ご利用分)(${minThisTier} ~ ${maxThisTier}枚まで)`
          : `Ez-AiOCR ${plan.plan_name} 従量課金(${usageMonth} ご利用分)(${minThisTier + 1}枚 ~)`;
      if (usageThisTier > 0) {
        rows.push({
          name: name,
          quantity: usageThisTier,
          unitPrice: rate,
        });
      }
    }
  }

  const totalAmount = Math.floor(
    rows.reduce((accumulator, currentValue) => accumulator + currentValue.quantity * currentValue.unitPrice, 0)
  );
  const taxAmount = Math.floor(totalAmount * (plan.tax_percent / 100));
  const billingAmount = (totalAmount + taxAmount).toLocaleString();

  return (
    <Root>
      <DocumentDate>{date}</DocumentDate>
      <Header>
        <Title>請求書</Title>
        <EnglishTitle>INVOICE</EnglishTitle>
      </Header>
      <Summary>
        <Left>
          <BillingCompanySection>
            <BillingCompany>{billingCompany} 様</BillingCompany>
          </BillingCompanySection>
          <BillingAmountSection>
            <BillingDescription>下記のとおりご請求申し上げます。</BillingDescription>
            <BillingAmount>
              <BillingAmountLabel>ご請求金額</BillingAmountLabel>
              <BillingAmountValue>{billingAmount}円</BillingAmountValue>
            </BillingAmount>
          </BillingAmountSection>
        </Left>
        <Right>
          <BillerCompanySection>
            <BillingCompanyLogo src="/logo_yoko_c.jpg" />
            <BillingCompanySeal src="/company_seal.png" />
            <BillerCompany>Ezテクノロジーズ株式会社</BillerCompany>
            <BillerAddress>〒105-0022</BillerAddress>
            <BillerAddress>東京都港区海岸１丁目４番２２号</BillerAddress>
            <BillerTel>TEL: 03-6416-1895</BillerTel>
            <BillerRegistrationNumber>登録番号：T6011001121349</BillerRegistrationNumber>
          </BillerCompanySection>
          <PaymentDueSection>
            <PaymentDueLabel>お支払い期日</PaymentDueLabel>
            <PaymentDueValue>{paymentDue}</PaymentDueValue>
          </PaymentDueSection>
        </Right>
      </Summary>
      <Table width="100%">
        <TableHead>
          <TableRow>
            <TableHeadNameCell>品番･品名</TableHeadNameCell>
            <TableHeadQuantityCell>数量</TableHeadQuantityCell>
            <TableHeadUnitPriceCell>単価</TableHeadUnitPriceCell>
            <TableHeadAmountCell>金額</TableHeadAmountCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableNameCell>{row.name}</TableNameCell>
              <TableQuantityCell>{row.quantity.toLocaleString()}</TableQuantityCell>
              <TableUnitPriceCell>{row.unitPrice.toLocaleString()}</TableUnitPriceCell>
              <TableAmountCell>{(row.quantity * row.unitPrice).toLocaleString()}</TableAmountCell>
            </TableRow>
          ))}
          <TableRow>
            <TableBlankCell></TableBlankCell>
            <TableBlankCell></TableBlankCell>
            <TableTotalCell>小計</TableTotalCell>
            <TableAmountCell>{totalAmount.toLocaleString()}</TableAmountCell>
          </TableRow>
          <TableRow>
            <TableBlankCell></TableBlankCell>
            <TableBlankCell></TableBlankCell>
            <TableTotalCell>消費税(10%)</TableTotalCell>
            <TableAmountCell>{taxAmount.toLocaleString()}</TableAmountCell>
          </TableRow>
          <TableRow>
            <TableBlankCell></TableBlankCell>
            <TableBlankCell></TableBlankCell>
            <TableTotalCell>合計金額</TableTotalCell>
            <TableAmountCell>{billingAmount}</TableAmountCell>
          </TableRow>
        </TableBody>
      </Table>
      <Remarks>
        <RemarksText>備考:</RemarksText>
        <RemarksText>
          クレジットカード払いのお客様は、上記金額を当月月末にご登録のクレジットカードにより決済させていただきます。
        </RemarksText>
        <RemarksText>
          口座お引落のお客様は、上記金額を当月27日(27日が土日祝日の場合は翌営業日)にご登録の口座よりお引落させていただきます。
        </RemarksText>
      </Remarks>
    </Root>
  );
};

export default Invoice;

const Root = styled.div`
  // A4 size
  width: 595px;
  height: 847px;

  font-size: 10px;
  padding: 10px 25px;
`;

const DocumentDate = styled.div`
  line-height: 15px;
  font-size: 8px;
  text-align: right;
  margin-bottom: 10px;
`;

const Header = styled.div`
  height: 60px;
  text-align: center;
  margin-bottom: 10px;
`;
const Title = styled.div`
  line-height: 30px;
  font-size: 20px;
  letter-spacing: 25px;
  text-indent: 25px;
  border-bottom: 3px solid black;
`;
const EnglishTitle = styled.div`
  line-height: 20px;
  padding-top: 5px;
`;

const Summary = styled.div`
  height: 160px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const Left = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const BillingCompanySection = styled.div`
  border-bottom: 2px solid black;
`;
const BillingCompany = styled.div`
  line-height: 25px;
  font-size: 14px;
  word-break: auto-phrase;
  padding-left: 10px;
`;
const BillingDescription = styled.div`
  line-height: 15px;
  margin-bottom: 10px;
`;
const BillingAmountSection = styled.div``;
const BillingAmount = styled.div`
  display: flex;
`;
const BillingAmountLabel = styled.div`
  width: 110px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  background-color: #ddebf6;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
`;
const BillingAmountValue = styled.div`
  width: 170px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid black;
`;
const Right = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const BillerCompanySection = styled.div`
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;
const BillingCompanyLogo = styled.img`
  width: 100px;
`;
const BillingCompanySeal = styled.img`
  position: absolute;
  width: 60px;
  left: 125px;
  top: 10px;
`;
const BillerCompany = styled.div`
  line-height: 20px;
  font-weight: bold;
`;
const BillerAddress = styled.div`
  line-height: 20px;
  font-weight: bold;
`;
const BillerTel = styled.div`
  line-height: 20px;
  font-weight: bold;
`;
const BillerRegistrationNumber = styled.div`
  line-height: 15px;
`;
const PaymentDueSection = styled.div`
  height: 25px;
  display: flex;
`;
const PaymentDueLabel = styled.div`
  width: 100px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
  background-color: #ddebf6;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
`;
const PaymentDueValue = styled.div`
  width: 120px;
  line-height: 25px;
  text-align: center;
  border: 1px solid black;
`;

const Table = styled.table`
  margin-bottom: 20px;
`;
const TableHead = styled.thead`
  background-color: #ddebf6;
`;
const TableBody = styled.tbody``;
const TableRow = styled.tr``;
const TableHeadNameCell = styled.th`
  width: 330px;
  line-height: 30px;
  font-size: 12px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;
const TableHeadQuantityCell = styled.th`
  width: 60px;
  line-height: 30px;
  font-size: 12px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;
const TableHeadUnitPriceCell = styled.th`
  line-height: 30px;
  font-size: 12px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;
const TableHeadAmountCell = styled.th`
  width: 70px;
  line-height: 30px;
  font-size: 12px;
  border: 1px solid black;
`;
const TableNameCell = styled.td`
  line-height: 25px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;
const TableQuantityCell = styled.td`
  line-height: 25px;
  text-align: center;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;
const TableUnitPriceCell = styled.td`
  line-height: 25px;
  text-align: right;
  padding-right: 20px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;
const TableAmountCell = styled.td`
  line-height: 25px;
  text-align: right;
  padding-right: 5px;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;
const TableBlankCell = styled.td`
  line-height: 15px;
  border: none;
`;
const TableTotalCell = styled.td`
  line-height: 15px;
  font-weight: bold;
  padding-left: 5px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;

const Remarks = styled.section`
  height: 140px;
  padding: 5px;
  border: 1px solid black;
  margin-top: 80px;
`;
const RemarksText = styled.div`
  line-height: 15px;
  margin-bottom: 5px;
`;
