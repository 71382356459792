import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement } from 'react';
import styled from 'styled-components';

import getPath from '../../utils/getPath';

const StyledLabel = styled.label`
  text-align: left;
  width: 5.5rem;
  white-space: pre-wrap;
`;

interface Props {
  label: string;
  name: string;
  children: ReactElement;
  initialValue?: unknown;
  labelWidth?: string;
  path?: NamePath;
  marginBottom?: string;
}
const FormItemWithLabel = ({ children, initialValue, label, labelWidth, marginBottom, name, path = [] }: Props) => {
  return (
    <Form.Item
      initialValue={initialValue}
      label={<StyledLabel style={{ width: labelWidth, marginBottom: `${marginBottom}` }}>{label}</StyledLabel>}
      name={getPath(path, name)}>
      {children}
    </Form.Item>
  );
};

export default FormItemWithLabel;
