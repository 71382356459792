import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PageContainer from './components/Container/PageContainer';
import AuthenticatedRoute from './components/Lib/AuthenticatedRoute';
import LoadingScreen from './components/Loading/LoadingScreen';
import LoginPage from './page/Login/LoginPage';
import MyPage from './page/MyPage/MyPage';
import OcrResultPage from './page/OcrResult/OcrResultPage';
import PasswordChangePage from './page/PasswordChange/PasswordChangePage';
import PasswordForgetPage from './page/PasswordForget/PasswordForgetPage';
import SearchPage from './page/Search/SearchPage';
import SettingsPage from './page/Settings/SettingsPage';
import FreeConsultationPage from './page/StaticPage/FreeConsultationPage';
import HayawazaPage from './page/StaticPage/HayawazaPage';
// import AnnouncementsPage from './page/StaticPage/AnnouncementsPage';
import HelpPage from './page/StaticPage/HelpPage';
import TermsPage from './page/Terms/TermsPage';
import TopPage from './page/Top/TopPage';
import UsagePage from './page/Usage/UsagePage';
import UserCreatePage from './page/UserForm/UserCreatePage';
import UserEditPage from './page/UserForm/UserEditPage';
import UserListPage from './page/UserList/UserListPage';
import { useAuthStore } from './store/useAuthStore';

const App = () => {
  const { isAuthInitiated } = useAuthStore();

  return !isAuthInitiated ? (
    <LoadingScreen />
  ) : (
    <BrowserRouter>
      <Switch>
        <Route exact component={LoginPage} path="/login" />
        <Route exact component={PasswordForgetPage} path="/password/forget" />
        <Route exact component={PasswordChangePage} path="/password/change" />
        {/* <Route component={AnnouncementsPage} path="/announcements" /> */}
        <Route exact component={TermsPage} path="/terms" />
        <AuthenticatedRoute
          path="/"
          render={() => (
            <PageContainer>
              <Route exact component={TopPage} path="/" />
              <Route exact component={OcrResultPage} path="/result/:type/:group" />
              <Route exact component={UsagePage} path="/usage" />
              <Route exact component={UserListPage} path="/users" />
              <Route exact component={SettingsPage} path="/settings" />
              <Route exact component={MyPage} path="/mypage" />
              <Route exact component={UserCreatePage} path="/users/create" />
              <Route exact component={UserEditPage} path="/users/edit/:userId" />
              <Route exact component={SearchPage} path="/documents/search" />
              <Route exact component={HelpPage} path="/help" />
              <Route exact component={FreeConsultationPage} path="/consultation" />
              <Route exact component={HayawazaPage} path="/hayawaza" />
            </PageContainer>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
