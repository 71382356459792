import { Form, Input, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { ReactElement, ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import Badge from '../../../components/Badge/Badge';
import { ExcludedIcon, FailedIconFilled, SuccessIcon } from '../../../components/Icons/StatusIcon';
import { DocumentBankBookEntity } from '../../../generated-api/api';
import useBankbookStore from '../../../store/useBankbookStore';
import { useDocument } from '../../../store/useDocumentListStore';
import { convertVariableTableFormatToCsv } from '../../../utils/convertCSVColumns';
import ensureDefined from '../../../utils/ensureDefined';
import ocrTypeNameDefinitions from '../../../utils/ocrTypeNameDefinitions';
import parseCurrencyInt from '../../../utils/parseCurrencyInt';
import BalanceCalcForm from '../BalanceCalcForm';
import { calcBalance } from '../calcBalance';
import { PrependComponentProp } from '../RowInputForm';
import {
  VariableLengthInputForm,
  VariableLengthInputFormResult,
  variableLengthInputFormResultToEntity,
} from '../VariableLengthInputForm';
import { CSVConvertResult, CSVData, OcrResultPageDefinition, ResultPageDocument } from './types';

const calcResultWidth = '2rem';
const CalcResultContainer = styled(Form.Item)`
  width: ${calcResultWidth};
`;
function CalcResult({ id, value }: PrependComponentProp<string>): ReactElement {
  return (
    <CalcResultContainer initialValue={value} name={['balance_calc', id ?? '']}>
      {renderCalcResult(value, true)}
    </CalcResultContainer>
  );
}

const CalcResultHeaderContainer = styled.div`
  flex-shrink: 0;
  width: ${calcResultWidth};
  margin-right: 8px;
  margin-bottom: 24px;
`;
function CalcResultHeader() {
  return <CalcResultHeaderContainer>残高計算結果</CalcResultHeaderContainer>;
}

const columnNameMapper = new Map<string, string>([
  ['date', '日付'],
  ['tran_amount1', '取引金額1'],
  ['tran_amount2', '取引金額2'],
  ['withdrawals', 'お支払い'],
  ['deposits', 'お預かり'],
  ['balance', '残高'],
]);

interface Props {
  groupId: string;
  documentId: string;
  entity: DocumentBankBookEntity;
  setChanged: (newValue: boolean) => void;
}
function BankBookFormInput({ documentId, groupId, setChanged }: Props): ReactElement {
  const { document } = useDocument<DocumentBankBookEntity>(groupId, documentId);
  const { selectedColumnName } = useBankbookStore(groupId, documentId);

  const mapBalanceCalcResult = useCallback((entity, index: number): string | undefined => {
    const typedEntity = entity as DocumentBankBookEntity;
    if (typedEntity.balance_calculation_result) {
      return typedEntity.balance_calculation_result[index];
    }
    return undefined;
  }, []);

  if (document == null) {
    return <></>;
  }

  const record = document.latest_revision;
  const columnNames = record.column_names ?? [];
  const namedColumnNames = columnNames.filter((name) => !name.includes('column'));
  const nonNamedColumnNames = columnNames.filter((name) => name.includes('column'));
  const sortedColumnNames = [...namedColumnNames, ...nonNamedColumnNames];

  const indexMap = columnNames.reduce<Record<string, number>>((accumulator, current, index) => {
    accumulator[current] = index;
    return accumulator;
  }, {});

  const rows = record.rows?.map((row) => {
    return sortedColumnNames.map((item) => {
      const originalIndex = indexMap[item];
      return row[originalIndex];
    });
  });

  const convertedColumnName = sortedColumnNames.map((name) => columnNameMapper.get(name) ?? name);
  const convertedEntity = { ...record, column_names: convertedColumnName, rows: rows };

  return (
    <>
      <VariableLengthInputForm
        additionalColumns={{
          mapper: mapBalanceCalcResult,
          PrependComponent: CalcResult,
          PrependHeaderComponent: CalcResultHeader,
        }}
        columnTypes={record.column_names?.map((name) => (isAmountColumn(name) ? { currency: true } : {})) ?? []}
        entity={convertedEntity}
        setChanged={setChanged}
      />
      {/* 保存時に残高計算をするために、テーブル側で選択した値を hidden パラメータとして渡す */}
      {/* See: src/page/OcrResult/BalanceCalcForm.tsx */}
      <Form.Item hidden initialValue={selectedColumnName} name="calcTargetColumn">
        <Input hidden type="hidden" value={selectedColumnName ?? undefined} />
      </Form.Item>
    </>
  );
}

const amountColumns = new Set([
  'withdrawals',
  'deposits',
  'balance',
  'tran_amount1',
  'tran_amount2',
  'お支払い',
  'お預かり',
  '残高',
  '取引金額1',
  '取引金額2',
]);

function isAmountColumn(name: string) {
  return amountColumns.has(name);
}

function applyColumnNamesMapping(columnNames: string[] | undefined) {
  if (columnNames == null) {
    return columnNames;
  }
  return columnNames.map((column) => columnNameMapper.get(column) ?? column);
}

function wrapExpandedBankBookTable(record: ResultPageDocument<DocumentBankBookEntity>): ReactNode {
  return !record.error ? <ExpandedBankBookTable record={record} /> : <></>;
}

interface ExpandedTableProps {
  record: ResultPageDocument<DocumentBankBookEntity>;
}
function ExpandedBankBookTable({ record }: ExpandedTableProps): ReactElement {
  const columnNames = ensureDefined(record.latest_revision.column_names);
  const rows = ensureDefined(record.latest_revision.rows);

  const data = rows.map((row, index) => {
    return row.reduce<Record<string, string>>(
      (accumulator, current, index) => {
        accumulator[columnNames[index]] = current;
        return accumulator;
      },
      { 残高計算結果: record.latest_revision.balance_calculation_result?.[index] ?? '' }
    );
  });

  return (
    <Table dataSource={data} pagination={false}>
      {record.latest_revision.balance_calculation_result && (
        <Column dataIndex="残高計算結果" render={(value) => renderCalcResult(value, false)} title="残高計算結果" />
      )}
      {columnNames.map((name) => (
        <Column
          key={name}
          dataIndex={name}
          render={(value) => renderCurrencyOrValue(name, value)}
          title={columnNameMapper.get(name) ?? name}
        />
      ))}
    </Table>
  );
}

function renderCurrencyOrValue(name: string, value: string | undefined) {
  const parsedValue = parseCurrencyInt(value);
  if (!isAmountColumn(name) || isNaN(parsedValue)) {
    return value;
  }
  return (
    // 金額のセルは右寄せにする
    // 今後こういった特定のセルに対して特別な装飾を施して欲しいというような機能要望があればそういう仕組みから作ったほうがいいと思う
    <div style={{ textAlign: 'right' }}> {parsedValue.toLocaleString('ja-JP', { currency: 'JPY' })}</div>
  );
}

function renderCalcResult(result: string | undefined, icon: boolean) {
  switch (result) {
    case '':
      return icon ? <ExcludedIcon /> : <Badge variant="default">計算対象外</Badge>;
    case 'true':
      return icon ? <SuccessIcon /> : <Badge variant="success">問題なし</Badge>;
    case 'false':
      return icon ? <FailedIconFilled /> : <Badge variant="danger">エラー</Badge>;
    default:
      return <></>;
  }
}

function convertBankBookCsv(data: ResultPageDocument<DocumentBankBookEntity>[]): CSVConvertResult {
  const readableColumnNameResultPageDocuments = data.map((record) =>
    record.latest_revision // null じゃないときだけ column_names を置き換え
      ? {
          ...record,
          latest_revision: {
            ...record.latest_revision,
            column_names: applyColumnNamesMapping(record.latest_revision.column_names),
          },
        }
      : record
  );

  const csvData = convertVariableTableFormatToCsv(readableColumnNameResultPageDocuments);

  const sorted = csvData.map((record) =>
    Object.entries(record)
      // isAmountColumn で定義されている金額カラムはCSVの右側列に寄せる
      .sort(([a], [b]) => (isAmountColumn(a) ? 1 : 0) - (isAmountColumn(b) ? 1 : 0))
      .reduce<CSVData[number]>((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  );
  return {
    csv: sorted,
  };
}

type BankbookEditFormResult = VariableLengthInputFormResult<DocumentBankBookEntity> & {
  type: string;
  balance_calc: Record</* rowId */ string, string | undefined>;
  columns: Record<string, string>;
  table: Record<string, Record<string, string>>;
  calcTargetColumn: string | undefined;
};

function formDataToEntity(values: BankbookEditFormResult) {
  const { column_names, rows } = variableLengthInputFormResultToEntity(values);
  if (values.calcTargetColumn == null) {
    const calcResult = values.rowIndices.map((item) => {
      const id = Object.keys(item)[0];
      return values.balance_calc[id] ?? 'undefined';
    });
    return { balance_calculation_result: calcResult, type: 'bankbook' as const, column_names, rows };
  } else {
    const calcResult = calcBalance(values.calcTargetColumn, rows, column_names, columnNameMapper);
    return { balance_calculation_result: calcResult, type: 'bankbook' as const, column_names, rows };
  }
}

const bankBookResultPageDefinition: OcrResultPageDefinition<DocumentBankBookEntity> = {
  name: ocrTypeNameDefinitions.bankbook,
  tabs: [
    {
      tableColumns: [
        {
          dataIndex: ['latest_revision'],
          title: '残高計算',
          render: (_, record) => {
            return !record.error ? (
              <BalanceCalcForm
                columnNameMapper={columnNameMapper}
                documentId={record.document_id}
                groupId={record.group_id}
              />
            ) : (
              <></>
            );
          },
        },
      ],
      tableExpandable: {
        expandedRowRender: wrapExpandedBankBookTable,
      },
      csvColumns: {},
    },
  ],
  formComponent: BankBookFormInput,
  formDataToEntity: formDataToEntity,
  csvDataConverters: [
    {
      converter: convertBankBookCsv,
    },
  ],
};

export default bankBookResultPageDefinition;
