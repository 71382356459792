import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ComponentType, ReactElement, useCallback, useState } from 'react';
import { useList } from 'react-use';
import short from 'short-uuid';
import styled from 'styled-components';

import getPath from '../../utils/getPath';
import parseCurrencyInt from '../../utils/parseCurrencyInt';
import { ColumnNameLabel } from './ColumnNameLabel';
import { DocumentEntities } from './defs/types';
import { ColumnType, PrependComponentProp, RowInputForm } from './RowInputForm';

export type AdditionalColumnsResultType<DocumentEntityType extends DocumentEntities> = {
  [Key in keyof DocumentEntityType]: DocumentEntityType[Key];
};

export type VariableLengthInputFormResult<AdditionalColumnsResult extends DocumentEntities> = {
  table: Record<string, Record<string, string>>; // rowId, columnIdからなる2次元 map
  columns: Record<string, string>; // columnId からなるカラム名の map
  rowIndices: Record<string, never>[]; // rowId と 列の並びを保持する map
  columnIndices: Record<string, never>[]; // columnId と 列の並びを保持する map
} & AdditionalColumnsResultType<AdditionalColumnsResult>; // 追加カラムのデータ

interface AdditionalColumns<PrependValue> {
  PrependComponent: ComponentType<PrependComponentProp<PrependValue>>;
  PrependHeaderComponent: ComponentType;
  mapper: (entity: VariableLengthInputFormEntity, index: number) => PrependValue | undefined;
}

interface Props<PrependValue> {
  columnTypes?: ColumnType[];
  entity: VariableLengthInputFormEntity;
  setChanged: (newValue: boolean) => void;
  additionalColumns?: AdditionalColumns<PrependValue>;

  path?: NamePath;
}

export interface VariableLengthInputFormEntity {
  column_names?: string[];
  rows?: string[][];

  rowIds?: string[];
  columnIds?: string[];
}

export const VariableLengthInputForm = <PrependValue,>({
  additionalColumns,
  columnTypes = [],
  entity,
  path,
  setChanged,
}: Props<PrependValue>): ReactElement => {
  /*
    OCR取得が完了するまでリロードが走るので、
    データ内容が一緒でも oldEntity !== newEntity となる可能性があるため、
    useMemo ではなく useState を使用している
  */
  const [initialColumnNames] = useState(() => {
    return (
      entity.column_names?.reduce<Record<string, string>>((accumulator, current) => {
        accumulator[short.generate()] = current;
        return accumulator;
      }, {}) ?? {}
    );
  });

  const [columnIds, { insertAt: insertColumnAt, removeAt: removeColumnAt }] = useList(Object.keys(initialColumnNames));

  // initialColumnNames と同理由で useMemo ではなく useState を使用している
  const [initialRows] = useState(() => {
    return (
      entity.rows?.reduce<Record<string, Record<string, string>>>((accumulator, currentRow) => {
        accumulator[short.generate()] = currentRow.reduce<Record<string, string>>((prev, current, index) => {
          prev[columnIds[index]] = current;
          return prev;
        }, {});
        return accumulator;
      }, {}) ?? {}
    );
  });

  const [rowIds, { insertAt: insertRowAt, removeAt: removeRowAt }] = useList(Object.keys(initialRows));

  const [additionalData, setAdditionalData] = useState<Record<string, PrependValue | undefined>>(() => {
    return rowIds.reduce<Record<string, PrependValue | undefined>>((map, rowId, rowIndex) => {
      map[rowId] = additionalColumns?.mapper(entity, rowIndex);
      return map;
    }, {});
  });

  const addColumn = useCallback(
    (index: number) => {
      insertColumnAt(index + 1, short.generate());
      setChanged(true);
    },
    [insertColumnAt, setChanged]
  );
  const removeColumn = useCallback(
    (index: number) => {
      removeColumnAt(index);
      setChanged(true);
    },
    [removeColumnAt, setChanged]
  );

  const addRow = useCallback(
    (index: number) => {
      if (columnIds.length === 0) {
        // rowIds は 0 でも追加ボタンが表示されるので
        // column は 自動で追加するようにしておく
        insertColumnAt(0, '');
      }
      const rowId = short.generate();
      insertRowAt(index + 1, rowId);
      const newAdditionalData = { ...additionalData };
      newAdditionalData[rowId] = undefined;
      setAdditionalData(newAdditionalData);
      setChanged(true);
    },
    [columnIds.length, insertRowAt, additionalData, setChanged, insertColumnAt]
  );
  const removeRow = useCallback(
    (index: number) => {
      removeRowAt(index);
      setChanged(true);
    },
    [removeRowAt, setChanged]
  );

  return (
    <FormContainer>
      <ColumnNameLabel
        addColumn={addColumn}
        columnIds={columnIds}
        columnNames={initialColumnNames}
        path={path}
        prependHeaderComponent={additionalColumns?.PrependHeaderComponent}
        removeColumn={removeColumn}
      />
      <div key={rowIds.length} hidden>
        {rowIds.map((rowId, index) => (
          // 列の並びを維持するために index と rowId の関係を object で保持する
          // FIXME: rowId を value として持つと rowIds が更新されても value が更新されないため name にキーとして持たせる
          // FYI: Form.Item の value を動的に変化させるためには form.setFieldValue を使う必要がある
          <Form.Item key={rowId} initialValue={rowId} name={getPath(path, 'rowIndices', index, rowId)}>
            <Input type="text" />
          </Form.Item>
        ))}
        {columnIds.map((columnId, index) => (
          <Form.Item key={columnId} initialValue={columnId} name={getPath(path, 'columnIndices', index, columnId)}>
            <Input type="text" />
          </Form.Item>
        ))}
      </div>
      <RowInputForm
        addRow={addRow}
        columnIds={columnIds}
        columnTypes={columnTypes}
        path={path}
        prependComponent={additionalColumns?.PrependComponent}
        prependData={additionalData}
        removeRow={removeRow}
        rowIds={rowIds}
        rows={initialRows}
      />
    </FormContainer>
  );
};

export function variableLengthInputFormResultToEntity<T extends DocumentEntities>(
  values: VariableLengthInputFormResult<T>
) {
  const rowIds = values.rowIndices.map((row) => Object.keys(row)[0]);
  const columnIds = values.columnIndices.map((col) => Object.keys(col)[0]);

  const columnNames = columnIds.map((id, index) => {
    const columnName: string = values.columns[id];
    if (columnName.indexOf('column') != -1) {
      return 'column' + (index + 1);
    }
    return values.columns[id];
  });
  const setColumnNames = new Set(columnNames);
  if (columnNames.length != setColumnNames.size) {
    throw Error('カラム名が重複しています。');
  }

  const rows = rowIds.map((rowId) =>
    columnIds.map((columnId) => {
      const parsed = parseCurrencyInt(values.table[rowId][columnId]);
      return isNaN(parsed) ? values.table[rowId][columnId] : parsed.toString();
    })
  );

  return {
    type: values.type,
    rows,
    column_names: columnNames,
  };
}

const FormContainer = styled.div`
  overflow: scroll;
  max-height: 70vh;
  margin-bottom: 8px;
`;
