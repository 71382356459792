import { Button } from 'antd';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { CompanyUsage, Plan } from '../../generated-api';
import { meSelector } from '../../store/useMeStore';
import Invoice from './Invoice';

interface Props {
  plan: Plan;
  usage: CompanyUsage;
}

const InvoicePdfDownloadButton = ({ plan, usage }: Props): ReactElement => {
  const { company_name } = useRecoilValue(meSelector);
  const handleDownloadPdf = async () => {
    const element = document.createElement('div');
    element.style.width = '595px';
    const output = renderToStaticMarkup(<Invoice billingCompany={company_name} plan={plan} usage={usage} />);
    element.innerHTML = output;
    document.body.appendChild(element);

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4',
    });

    if (!element) {
      return;
    }
    const canvas = await html2canvas(element, { scale: 2 });
    element.remove();
    const dataUrl = canvas.toDataURL('image/jpeg');

    const imgProperties = doc.getImageProperties(dataUrl);

    const width = doc.internal.pageSize.getWidth();
    const height = (imgProperties.height * width) / imgProperties.width;

    doc.addImage(dataUrl, 'JPEG', 0, 0, width, height);

    const now = format(new Date(), 'yyyy-MM-dd');
    doc.save(`${now}_請求書.pdf`);
  };

  return (
    <Root>
      <Button type="primary" onClick={handleDownloadPdf}>
        請求書Download
      </Button>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export default InvoicePdfDownloadButton;
